import moment from 'moment';

const firstPayDate = new Date('2019-01-06');
const daysPerPay = 14;
const twoWeeksAgoInDays = 13;
const startYear = 2019;
const payPerYear = 26;

export const addDays = (date, days) => {
  const result = new Date(date);
  return result.setDate(result.getDate() + days);
};

export const getPayEndDate = (year, payNumber) => {
  const yearDifference = Math.abs(startYear - year);
  if (year < moment().year()) {
    const dayDifference = yearDifference * payPerYear * daysPerPay - (payNumber - 1) * daysPerPay;
    return moment(firstPayDate)
      .subtract(dayDifference, 'days')
      .format('YYYY-MM-DD');
  }
  const dayDifference = yearDifference * payPerYear * daysPerPay + (payNumber - 1) * daysPerPay;
  return moment(firstPayDate)
    .add(dayDifference, 'days')
    .format('YYYY-MM-DD');
};

export const getPayStartDate = endDate =>
  moment(endDate)
    .subtract(twoWeeksAgoInDays, 'days')
    .format('YYYY-MM-DD');
